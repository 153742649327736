/* vars */

:root {
    --radius: 4px;
    --padding: 16px;
    --color: #fff;
    --color-accent:#ffcc00;
    --bg-accent:rgba(0, 0, 0, 0.9);
    --radius: 4px;
    --color: #fff;
    --bg: rgba(0, 0, 0, 0.4);
    --success-color: #00ff00;
    --bg-secondary: #00000055;
    --bg-hover: #000000cc;
    --google: #4285f4;
    --facebook: #3b5998;
    --twitter: #1da1f2;
    --github: #333;
    --google-hover: #357ae8;
    --facebook-hover: #2d4373;
    --twitter-hover: #1a91da;
    --github-hover: #222;

}