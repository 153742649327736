.menu-tiles {
  display: grid;
  flex: 1;
  // margin-left: 15px;
  // margin-right: 15px;
  // margin-bottom: 15px;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  grid-template-columns: repeat(5, 1fr); /* 6 columns */
  grid-template-rows: repeat(3, 1fr); 
  
  // max-height: calc(3 * (100px + 15px)); /* 3 rows height + gaps (adjust 100px as needed) */
  overflow-y: auto; 

  grid-gap: 15px;
  &.hidden {
    display: none;
  }

  .menu-tile {
    display: flex;
    padding: 10px 20px;
    aspect-ratio: 1;
    border-radius: 8px;

    background: var(--bg); /* Black transparent background */
    position: relative;
        
    .tile-title {
      position: absolute;
      bottom: 10px;
      font-size: 1.2em;
      font-weight: 600;
      color: var(--color);
    }
  }
  &.focused { 
    .selected {
        // shadow
        box-shadow: 0 0 15px 0 rgba(255, 204, 0, 0.5);
        background-color: var(--bg-accent);
        color: var(--color-accent);
    }            
  }
}