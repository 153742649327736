.controller-settings-menu {
    display: flex;
    flex-direction: column;
    padding: 15px;

    .controller-axis{
        display: flex;
        flex-direction: column;
        gap: 15px;
        .controller-select {
            display: flex;
            gap: 15px;
            flex-wrap: wrap;
            flex-direction: row;
            padding-left: 15px;
            padding-top: 8px;
            padding-bottom: 8px;
            border-radius: 8px;
            background-color: rgba(0, 0, 0, 0.3);

            select {
                background-color: rgba(0, 0, 0, 0.3);
                border: none;
                color: #fff;
                padding: 5px;
                    
            }
        }
        .controller-axis-table{
            background-color: rgba(0, 0, 0, 0.3);
            
            border-radius: 8px;
            td {
                padding: 5px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                //first td in row
                &:first-child {
                    // border-left: 1px solid rgba(255, 255, 255, 0.1);
                    padding-left: 15px;
                }
                // next tds in row
                &:not(:first-child) {
                    // border-left: 1px solid rgba(255, 255, 255, 0.1);
                    text-align: center;
                }
                &.header {
                    font-weight: bold;
                    color: #ffcc00;
                
                }
                .axis-value {
                    height: 20px;
                    display: block;
                    width: 100%;
                    position: relative;
                    // this should be backgorund but transparent ffcc00
                    background: rgba(255, 204, 0, 0.3);
                    display: flex;
                }
                input {
                    background-color: rgba(0, 0, 0, 0);
                    border: none;
                    color: #fff;
                    padding: 5px;
                    border-radius: 4px;
                   
                    &[type="number"] {
                        display: flex;
                        flex-grow: 1;
                        width: 70px;
                        text-align: center;
                        //focus border
                     
                        &:hover {
                            background-color: rgba(0, 0, 0, 0.3);
                        }
                    }

                }
            }
        }
    }
    .value-bar {
        position: absolute;
        height: 100%;
        margin-top: -1px;
        background-color: rgba(255, 204, 0, 0.6);
    }
    .axis-chooser {
        position: relative; // Allows dropdown to position relative to parent
        display: inline-block;


       .axis-chooser-button {
            background-color: rgba(0, 0, 0, 0.3);
            border: none;
            color: #fff;
            padding: 2px;
            border-radius: 4px;
            min-width: 90px;
            position: relative;
            cursor: pointer;
            &:hover {
                background-color: rgba(0, 0, 0, 0.5);
            }
        }
        // dropdown should open below the button and should be full width
        .axis-chooser-dropdown {
            position: absolute;
            width: 100%;
            top: 100%; // Place dropdown below the button
            background-color: rgba(255,255,255, 1);
            left: 0;
            border-radius: 4px;
            border: none;
            z-index: 1000;
            overflow-y: auto; 
            gap: 5px;

            .axis-chooser-item {
                background-color: rgba(0, 0, 0, 0.85);
                margin-top: 2px;
                display: block;
                cursor: pointer;
                width: 100%;
                color: white;
                position: relative;

                &:hover {
                    background-color: rgba(0, 0, 0, 1);
                    color:  #ffcc00;
                }                
            }
            
        }
    }
}