.obstacles {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    width: 100%;
    gap: 1rem;
    .obstacle {
        // should have border
        border-radius: 4px;
        // bckground same as transparent button
        background: rgba(0, 0, 0, 0.3);
        display: flex;
        // I need to show name of obstalce in one row and below in the row should show buttons
        flex-direction: column;
        gap: 0.5rem;
        width: 100%;
        padding: 0.5rem;
        
        .obstacle-name {
            display: flex;
            gap: 0.5rem;
            width: 100%;
            .obstacle-name-input {
                width: 100%;
            }
        }
        .obstacle-buttons {
            display: flex;
            gap: 0.5rem;
            width: 100%;
            .transparent-button {
                
                border-radius: 4px;
                border: none;
                padding: 8px 16px;
                cursor: pointer;
                transition: background 0.3s ease;
                user-select: none;
                &:hover {
                    background: rgba(255, 255, 255, 0.3);
                }
                &.primary {
                    background: #ffcc00A0;
                    color: #000;
                    &:hover {
                        background: #ffcc00;
                        color: #000;
                    }
                }
            }
        }

    }
}