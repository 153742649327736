.map-details-holder {
    /* On top center of the screen */
    position: fixed;
    top: 16px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1000;
    display: flex;
    gap: 15px;
    

}
.map-details-panel-holder {
    position: fixed;
    top: 64px;
    left: 50%;
    width: 50%;
    transform: translate(-50%, 0);
    z-index: 1000;
    display: flex;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    border: none;
    padding: 8px 16px; 
}
