.menu-tabs {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;

    .menu-tab {
        padding: 10px 20px;
        background: rgba(0, 0, 0, 0.4); /* Black transparent background */
        border-radius: var(--radius);
        color: #fff; /* White text color */
        text-align: center;
        position: relative;
        text-transform: uppercase;

        cursor: pointer;
        transition: background 0.3s ease, transform 0.2s ease;
        user-select: none;
        &:hover {
            background: rgba(255, 255, 255, 0.3); /* Light hover effect */
        }
        &.selected {
            background: var(--bg-accent);
            color: var(--color-accent);
            font-weight: bold;
        }
        .tab-badge {
            position: absolute;
            top: 0;
            right: 0;
            background: var(--color-accent);
            color: #000;
            padding: 0.1rem 0.3rem;
            font-size: 0.5rem;
            font-weight: bold;
            
        }

    }
    &.focused { 
        .selected {
            // shadow
            box-shadow: 0 0 30px 0 rgba(255, 204, 0, 0.5);
            color: #ffcc00;
        }            
    }
}
.menu-tab-content {
    display: flex;
    // flex grow to fill the space
    flex: 1;
    padding: 10px 20px;
    border-color:rgba(0, 0, 0, 0.3);
    border-style: solid;
    border-width: 2px;
    gap: 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    // background: rgba(0, 0, 0, 0.3); /* Black transparent background */
    border-radius: 8px;
        
    &.hidden {
        display: none;
    }
}