.transparent-button {
    background: var(--bg);
    color: var(--color);
    border-radius: var(--radius);
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    transition: background 0.3s ease;
    user-select: none;
    &:hover {
        background: rgba(255, 255, 255, 0.3);
    }
    &.primary {
        background: #ffcc00A0;
        color: #000;
        &:hover {
            background: #ffcc00;
            color: #000;
        }
    }
}
.offcanvas {
    position: fixed;
    top: 0;
    bottom: 0;
    max-width: 110px;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    // scroll if conntent is too long
    &.left {
        left: 0;
        right: auto;
    }
    &.right {
        right: 0;
        left: auto;
    }
    .offcanvas-content {
        overflow-x: auto; 
        flex-grow: 1;

    }
    .offcanvas-title {
        background: #ffcc00;
        color: #000;
        padding: 4px;
        font-weight: bold;
        cursor: pointer;
    }


}
.dropables {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    padding-left: 4px;
    padding-right: 4px;

    .dropable {
        border: 1px solid #ccc;
        border-radius: 8px;
        margin: 4px;
        cursor: pointer;
        position: relative;
        width: 80px;
        height: 80px;
        padding: 0;
        transition: background 0.3s ease;
        &.dragging {
            border: 1px solid  #ffcc00;
        }
        &:hover {
            border: 1px solid  #ffcc00;;
        }
        .dropable-title {
            position: absolute;
            bottom: 9px;
            left: 9px;
            color: #fff;
            // need balck shadow to make text readable
            text-shadow: 1px 1px 1px #000;
        }
        img {
            width: 100%;
            height: auto;
            overflow: hidden;
            border-radius: 8px;
        }

    }
}