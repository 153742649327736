.flex-col {
    display: flex;
    flex-direction: column;
}
.flex-row {
    display: flex;
    flex-direction: row;
}
.center {
    justify-content: center;
    align-items: center;
}
.label {
    font-size: 1.2em;
    font-weight: bold;
    padding: 4px;
    color: white;
    user-select: none;
}
.label-input {
    padding: 8px;
}
.icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
    color: white;
    font-size: 24px;
    fill: white;
}
.selected {
    .icon {
        fill: var(--color-accent);
        color: var(--color-accent);
    }
}

.menu-panel {
    display: flex;
    flex-grow: 1;
    margin: var(--padding);
    padding: var(--padding);
    border-radius: var(--radius);
    background-color: var(--bg);
}