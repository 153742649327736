/* @import 'bootstrap/dist/css/bootstrap.min.css'; */


@import "./bootstrap-icons/bootstrap-icons.css";


@import "./vars.css";

@import "./components/util.css";
.value {
    font-weight: bold;
    color: var(--color);
    min-width: 30px;
    text-align: right;
    margin-right: 16px;
}

/* body, html, button, input, select, textarea, label {
    font-family: monospace;
    color: var(--color);
} */
.hidden {
    display: none;
}

#render-canvas {
    position: fixed;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    touch-action: none;
    outline: none;
    z-index: -1;
    visibility: hidden;
}



#spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* to center it vertically on the screen */
    width: 100vw; /* to center it horizontally on the screen */
    z-index: 0;
    display: none;
}

#spinner img {
    animation: spin 1s linear infinite;
    color: white;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.border {
    border: 1px solid var(--color);
    border-radius: 4px;
}