.main-menu {
    display: flex;
    flex-direction: column;
    position: static;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;

    .grid-menu-tabs {
        display: flex;
        gap: 15px;
        margin-bottom: 15px;
        margin-top: 15px;
        margin-left: 15px;

        .grid-menu-tab {
            padding: 10px 20px;
            background: rgba(0, 0, 0, 0.3); /* Black transparent background */
            border-radius: 2px;
            text-align: center;
            position: relative;

            cursor: pointer;
            transition: background 0.3s ease, transform 0.2s ease;
            user-select: none;
            &:hover {
                background: rgba(255, 255, 255, 0.3); /* Light hover effect */
            }
            &.selected {
                background: rgba(0, 0, 0, 0.9); /* Slightly darker for the selected state */
                color: #ffcc00; /* Highlighted text color */
                // give me more plesant color
                
                font-weight: bold;
            }
            // if is hover and selected at the same time
            // &:hover.selected {
            //     background: rgba(0, 0, 0, 0.8);
            //     // transform: scale(1.0);
            // }
            //if grid-menu-tabs has class choosing and item is selected
            
            // should be in top right corner 
            .tab-badge {
                position: absolute;
                top: 0;
                right: 0;
                background: #ffcc00;
                color: #000;
                padding: 0.1rem 0.3rem;
                font-size: 0.5rem;
                font-weight: bold;
               
            }

        }
        &.choosing { 
            .selected {
                // shadow
                box-shadow: 0 0 30px 0 rgba(255, 204, 0, 0.5);
                color: #ffcc00;
            }            
        }

    }
    .grid-menu-items {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        padding-left: 15px;
        padding-right: 15px;
        grid-gap: 1rem;
    
        .grid-menu-item {
            padding: 10px 20px;
            background: rgba(0, 0, 0, 0.2); /* Black transparent background */
            border-radius: 2px;
            position: relative;

            cursor: pointer;
            transition: background 0.3s ease, transform 0.2s ease;
            user-select: none;
            &:hover {
                background: rgba(255, 255, 255, 0.2); /* Light hover effect */
            }
            .grid-menu-item-icon {
                font-size: 2rem;
                margin-bottom: 0.5rem;
            }
            .grid-menu-item-title {
                font-size: 1.2rem;
                font-weight: 500;
            }
        }
    }
    .grid-menu-next {
        grid-column: span 2;
    }
    .menu-content {
        display: flex;
        flex-grow: 1;
    }
    .menu-footer {
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
        background: var(--bg);
        border-radius: var(--radius);
       
    }
}