.auth-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.auth-title {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: var(--color);
}
.auth-page__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 15rem;
    /* height: 20rem; */
    border: 1px solid var(--bg);
    border-radius: var(--radius);
    padding: 16px;
    box-sizing: border-box;
    background-color: var(--bg);
}
.auth-page__content > * {
    margin: 0.5rem;
}
.auth-page__content > :last-child {
    margin-bottom: 0;
}
.auth-page__content__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 14rem;
}

.auth-page__content__item__label {
    font-size: 1.2rem;
}
.auth-page__content__item__input {
    width: 100%;
}
.auth-page__content__item__button {
    width: 100%;
}
.auth-button {
    width: 100%;
    border-radius: var(--radius);
    border: none;
    padding: 8px;
}
.button-google { background-color: var(--google); }
.button-google:hover { background-color: var(--google-hover); }
.button-facebook { background-color: var(--facebook); }
.button-facebook:hover { background-color: var(--facebook-hover); }