.game-button {
    position: fixed;
    top: 64px;
    right: 16px;
    width: 32px;
    height: 32px;
    z-index: 200;
    border-radius: var(--radius);
    background-color: var(--bg-secondary); 
}
.game-button:hover { background-color: var(--bg); }
.game-button.back { left: 16px; bottom:16px; top:auto; }
.game-button.camera { top: 100px; }
.game-button.config { top: 136px; }
.game-button.controller { top: 172px; }
.game-button.path { top: 208px; }
.game-button.play { top: 244px; }
.in-game-config {
    position: fixed;
    top: 0px;
    right: 0px;
    left:0px;
    bottom: 0px;
    /* width: 200px; */
    background-color: #000000aa;
    padding: 8px;
    z-index: 100;
    padding-left: 20%;
    padding-right: 20%;
}
.in-game-config > .close-button {
    top: 8px;
    right: 8px;
    
}