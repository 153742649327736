.node-details {
    padding: 4px;
    /* Round corners */
    border-radius: 4px;
    /* Border */
    border: 1px solid #000;
    /* height: 256px; */
}
.node-details > input {
    width: 100%;
}